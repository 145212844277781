import {
    CanvasController,
    editorConstants,
    useInteractionState,
    useViewState,
} from "@streamlux/animation-editor";
import React, { ReactElement, FC, useEffect } from "react";

type EditorProps = {};

const Editor: FC<EditorProps> = ({}): ReactElement => {
    const setViewState = useViewState(s => s.set);
    useEffect(() => {
        setViewState(s => {
            s.baseSegmentWidth = editorConstants.maxZoom;
        });
    }, []);
    return (
        <div
            className="flex-grow h-screen"
            style={{ background: "rgb(34, 33, 39)" }}
        >
            <CanvasController />
        </div>
    );
};

export default Editor;
